<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <div class="brand-link">
      <a href="restaurants">
        <span class="brand-text font-weight-light">Nhà hàng Kỷ Y</span>
      </a>
    </div>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
        </div>
        <div class="info">
          <p class="d-block title-main" id="name_user_login" @mouseover="showTooltip()" :data-original-title="name">{{ name }}</p>
          <a @click.prevent="handleBtnLogoutClick()" href="#" class="d-block">Đăng xuất</a>
        </div>
      </div>

      <!-- SidebarSearch Form -->
<!--      <div class="form-inline">-->
<!--        <div class="input-group" data-widget="sidebar-search">-->
<!--          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">-->
<!--          <div class="input-group-append">-->
<!--            <button class="btn btn-sidebar">-->
<!--              <i class="fas fa-search fa-fw"></i>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item" v-for="(item, index) in listMenus" :key="index">
            <template v-if="item.childrens === false">
              <router-link
                :to="{ name: item.routeName, query: item.query }"
                :class="item.isActive ? 'nav-link active' : 'nav-link'"
              >
                <i class="nav-icon" :class="item.iconSrc"></i>
                <p>
                  {{ item.label }}
                </p>
              </router-link>
            </template>
            <template v-else>
              <a href="#" class="nav-link" :class="item.isActive ? 'active' : ''">
                <i class="nav-icon" :class="item.iconSrc"></i>
                <p>
                  {{ item.label }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item  m-l-20"
                  v-for="(itemChild, indexChild) in item.childrens"
                  :key="indexChild"
                >
                  <router-link
                    :to="{ name: itemChild.routeName, query: itemChild.query  }"
                    :class="itemChild.isActive ? 'nav-link active' : 'nav-link'"
                  >
                    <i class="nav-icon" :class="itemChild.iconSrc"></i>
                    <p>
                      {{ itemChild.label }}
                    </p>
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
          <!-- <li class="nav-item">
            <a target="_blank" href="https://cnctor.notion.site/C-061f6a7b604644ff9ed0cd5b3d431699?pvs=4" class="nav-link">
              <i class="nav-icon fa fa-book"></i><p>使い方</p></a>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { ROUTES } from "@constants";
import { AUTH } from "@constants";
import {ENDPOINT} from "@constants";
export default {
  name: "SidebarLayout",
  data() {
    return {
      toggleValue: false,
      items: [
        // {
        //   routeName: ROUTES.ADMIN.ALL_USERS_LIST,
        //   path: "accounts",
        //   label: this.$t("nav.Account Admin"),
        //   iconSrc: "fa fa-user",
        //   childrens: false,
        //   query : {
        //     'filters.apartment_id.equal' : 'all',
        //     'filters.status_enabled.equal' : 'all',
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.APARTMENT_LIST,
        //   path: "apartments",
        //   label: this.$t("nav.Apartment List"),
        //   iconSrc: "fas fa-building",
        //   childrens: false,
        //   query : {'filters.pref_id.equal' : 'all'}
        // },
        {
          label: this.$t("nav.Bento Management"),
          path: null,
          iconSrc: "fab fa-first-order",
          childrens: [
            {
              routeName: ROUTES.ADMIN.KYY_CUSTOMERS_LIST,
              path: "kyy-customers",
              label: this.$t("nav.Account Customer"),
              iconSrc: "fa fa-user",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.MENU_STUDENT_LIST,
              path: "menus-student",
              label: this.$t("nav.Menu Student"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_STUDENT_ORDER_LIST,
              path: "student-orders",
              label: this.$t("nav.Student Orders"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_STUDENT_ORDER_BY_MONTHS,
              path: "order-statistics",
              label: 'Thống kê cơm hộp',
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_ORDER_EXPORT,
              path: "order-exporter",
              label: this.$t("nav.Export Order"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_ORDER_BY_CODE_EXPORT,
              path: "order-by-code-exporter",
              label: this.$t("nav.Export Order By Code"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_STUDENT_INVOICE_LIST,
              path: "invoice-sending",
              label: this.$t("nav.Sending Invoice"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {
                'filters.year_month.equal': moment().subtract(1, 'months').format('YYYY-MM')
              }
            },
          ]
        },
        {
          routeName: ROUTES.ADMIN.KYY_RESTAURANT_LIST,
          path: "restaurants",
          label: this.$t("nav.Restaurant"),
          iconSrc: "fab fa-first-order",
          childrens: false,
          query : {}
        },
        {
          label: this.$t("nav.Table Orders"),
          path: null,
          iconSrc: "fas fa-car",
          childrens: [
            {
              routeName: ROUTES.ADMIN.KYY_TABLE_ORDER_LIST,
              path: "table-orders",
              label: this.$t("nav.Table Orders"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_TABLE_ORDER_BY_MONTHS,
              path: "table-statistics",
              label: 'Thống kê đặt bàn',
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
          ]
        },
        {
          label: this.$t("nav.Menu Ship"),
          path: null,
          iconSrc: "fas fa-car",
          childrens: [
            {
              routeName: ROUTES.ADMIN.KYY_CATEGORY_LIST,
              path: "category",
              label: this.$t("nav.Category"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_CATEGORY_MENU_LIST,
              path: "cate-menus",
              label: this.$t("nav.Category Menu"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_STANDARD_ORDER_LIST,
              path: "standard-orders",
              label: this.$t("nav.Standard Orders"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_STANDARD_STATISTICS,
              path: "standard-statistics",
              label: this.$t("nav.Standard Statistics"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
          ]
        },
        {
          label: this.$t("nav.News Management"),
          path: null,
          iconSrc: "fa fa-info",
          childrens: [
            {
              routeName: ROUTES.ADMIN.KYY_NEWS_CATEGORY_LIST,
              path: "news-category",
              label: this.$t("nav.News Category"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_NEWS_LIST,
              path: "page-news",
              label: this.$t("nav.News List"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
          ]
        },
        {
          label: this.$t("nav.Settings"),
          path: null,
          iconSrc: "fa fa-cog",
          childrens: [
            {
              routeName: ROUTES.ADMIN.KYY_HOME_SETTING,
              path: "home-setting",
              label: this.$t("nav.Home Setting"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
            {
              routeName: ROUTES.ADMIN.KYY_WEB_SETTING,
              path: "web-setting",
              label: this.$t("nav.Web Setting"),
              iconSrc: "fab fa-first-order",
              childrens: false,
              query : {}
            },
          ]
        },
        // {
        //   routeName: ROUTES.ADMIN.BOOK_MASTER_LIST,
        //   path: "master-booking",
        //   label: this.$t("nav.Master Booking Management"),
        //   iconSrc: "fab fa-first-order",
        //   childrens: false,
        //   query : {
        //     'filters.apartment_id_equal.equal' : 'all',
        //     'filters.facility_id.equal' : 'all',
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.RESERVATION_LIST,
        //   path: "reservation",
        //   label: this.$t("nav.Customer Booking List"),
        //   iconSrc: "fab fa-first-order",
        //   childrens: false,
        //   query : {
        //     'filters.date.equal' : moment().format('YYYY-MM-DD'),
        //     'filters.status_booking_equal.equal' : 'all',
        //     'filters.apartment_id.equal' : 'all',
        //     'filters.start_time_between.between' : 'all',
        //     'filters.close_time_between.between' : 'all',
        //     'filters.facility_id.equal' : 'all',
        //     'filters.plan_id.equal' : 'all'
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.BOOKING_SCHEDULE_LIST,
        //   path: "booking-schedule",
        //   label: this.$t("nav.Booking Schedule"),
        //   iconSrc: "fas fa-calendar-alt",
        //   childrens: false,
        //   query:  {
        //     'status_booking' : 'all',
        //     'date' : moment().format('YYYY-MM-DD'),
        //     'apartment_id' : '',
        //     'customer_name' : ''
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.TRANSPORTS_LIST,
        //   path: "transport",
        //   label: this.$t("nav.Transport Management"),
        //   iconSrc: "fas fa-car",
        //   childrens: false,
        //   query:  {
        //     'filters.status_booking.equal' : 'all'
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.CUSTOMER_LIST,
        //   path: "customers",
        //   label: this.$t("nav.Customer List"),
        //   iconSrc: "fas fa-users",
        //   childrens: false,
        //   query : {
        //     'filters.status_enabled.equal' : 'all',
        //     'filters.apartment_id.equal' : 'all',
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.BEACONS_LIST,
        //   path: "beacons",
        //   label: this.$t("nav.Beacon Management"),
        //   iconSrc: "fa fa-tag",
        //   childrens: false,
        //   query : {
        //     'filters.status_enabled.equal': 'all',
        //     'filters.apartment_id.equal' : 'all'
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.GATEWAY_LIST,
        //   path: "gateways",
        //   label: this.$t("nav.Gateway Management"),
        //   iconSrc: "fab fa-hubspot",
        //   childrens: false,
        //   query : {
        //     'filters.status_enabled.equal': 'all',
        //     'filters.apartment_id_equal.equal' : 'all',
        //     'filters.facility_id.equal' : 'all'
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.FACILITY_LIST,
        //   path: "facilities",
        //   label: this.$t("nav.Facility Management"),
        //   iconSrc: "far fa-gem",
        //   childrens: false,
        //   query: {
        //     'filters.status_disp_crowded.equal' : 'all',
        //     'perPage' : '99999',
        //     'sortBy' : 'apartment_id',
        //     'filters.apartment_id.equal' : 'all',
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.INFORMATION_LIST,
        //   path: "information",
        //   label: this.$t("nav.Information"),
        //   iconSrc: "fa fa-info-circle",
        //   childrens: false,
        //   query: {
        //     'filters.infor_type.equal' : 'all',
        //     'filters.status_publishing.equal' : 'all'
        //   }
        // },
        // {
        //   label: this.$t("nav.Room Service"),
        //   path: null,
        //   iconSrc: "fa fa-bed",
        //   childrens: [
        //     {
        //       routeName: ROUTES.ADMIN.CATEGORY_LIST,
        //       path: "categories",
        //       label: this.$t("nav.Category Management"),
        //       iconSrc: "fa fa-bars",
        //       query: {
        //         'perPage' : '99999',
        //         'sortBy' : 'apartment_id',
        //         'filters.apartment_id.equal' : 'all',
        //       }
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.MENU_LIST,
        //       path: "menu",
        //       label: this.$t("nav.Menu Management"),
        //       iconSrc: "fa fa-bars",
        //       query:  {
        //         'filters.apartment_id.equal' : 'all',
        //         'filters.category_id.equal' : 'all',
        //         'filters.status_enabled.equal' : 'all'
        //       }
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.ORDER_LIST,
        //       path: "orders",
        //       label: this.$t("nav.Order List"),
        //       iconSrc: "fa fa-bars",
        //       query:  {
        //         'filters.status_delivered.equal' : 'all'
        //       }
        //     },
        //   ],
        // },
        // {
        //   routeName: ROUTES.ADMIN.SALE_LIST,
        //   path: "sale",
        //   label: this.$t("nav.Sale List"),
        //   iconSrc: "fas fa-shopping-cart",
        //   childrens: false,
        //   query: {
        //     'filters.month.equal' : moment().format('YYYY/MM/01'),
        //     'filters.apartment_id.equal' : 'all',
        //   }
        // },
        // {
        //   label: this.$t("nav.QA Chatbot"),
        //   path: null,
        //   iconSrc: "fas fa-question",
        //   childrens: [
        //     {
        //       routeName: ROUTES.ADMIN.BIG_CATEGORY_LIST,
        //       path: 'big-category',
        //       label: this.$t("nav.Big Category List"),
        //       iconSrc: "fa fa-bars",
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.MEDIUM_CATEGORY_LIST,
        //       path: 'medium-category',
        //       label: this.$t("nav.Medium Category List"),
        //       iconSrc: "fa fa-bars",
        //       query : {
        //         'filters.big_category_id.equal' : 'all'
        //       }
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.SMALL_CATEGORY_LIST,
        //       path: 'small-category',
        //       label: this.$t("nav.Small Category List"),
        //       iconSrc: "fa fa-bars",
        //       query : {
        //         'filters.big_category_id.equal' : 'all',
        //         'filters.medium_category_id.equal' : 'all'
        //       }
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.ANSWER_LIST,
        //       path: 'qa-answer',
        //       label: this.$t("nav.Answer List"),
        //       iconSrc: "fa fa-bars",
        //       query : {
        //         'filters.big_category_id.equal' : 'all',
        //         'filters.medium_category_id.equal' : 'all',
        //         'filters.category_id.equal' : 'all'
        //       }
        //     },
        //   ],
        // },
        // {
        //   routeName: ROUTES.ADMIN.DOWNLOAD_CSV,
        //   path: "download-csv",
        //   label: this.$t("nav.Download CSV"),
        //   iconSrc: "fas fa-download",
        //   childrens: false,
        //   query: {
        //     'filters.gender_code.equal' : 'all',
        //     'filters.from_register_date.between' : moment().date(0).format('YYYY-MM-01'),
        //     'filters.to_register_date.between' : moment().date(0).format('YYYY-MM-DD'),
        //     'filters.from_receipt_date.between' : moment().date(0).format('YYYY-MM-01'),
        //     'filters.to_receipt_date.between' : moment().date(0).format('YYYY-MM-DD'),
        //   }
        // },
        // {
        //   routeName: ROUTES.ADMIN.UPLOAD_CSV,
        //   path: "upload-csv",
        //   label: this.$t("nav.Upload CSV"),
        //   iconSrc: "fas fa-upload",
        //   childrens: false,
        // },
        // // {
        // //   routeName: ROUTES.ADMIN.RESTAURANTS_LIST,
        // //   path: "restaurants",
        // //   label: this.$t("nav.Restaurant Management"),
        // //   iconSrc: "fas fa-utensils",
        // //   childrens: false,
        // //   query : {
        // //     'filters.status_enabled.equal': 'all',
        // //     'filters.apartment_id.equal' : 'all',
        // //     'filters.facility_id.equal' : 'all'
        // //   }
        // // },
        // {
        //   label: this.$t("nav.Announcement Management"),
        //   path: null,
        //   iconSrc: "fas fa-flag",
        //   childrens: [
        //     {
        //       routeName: ROUTES.ADMIN.ANNOUNCEMENTS_LIST,
        //       path: null,
        //       label: this.$t("nav.List of announcements"),
        //       iconSrc: "fas fa-bars"
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.ANNOUNCEMENTS_MNG_LIST,
        //       path: null,
        //       label: this.$t("nav.AnnouncementMng Management"),
        //       iconSrc: "fas fa-bars",
        //       query : {
        //         'filters.status_enabled.equal': 'all',
        //       }
        //     },
        //     {
        //       routeName: ROUTES.ADMIN.SETING_NOTIFICATION_MAIL_LIST,
        //       path: 'notifies',
        //       label: this.$t("nav.Notification email settings"),
        //       iconSrc: "fas fa-bars",
        //     },
        //   ]
        // },
        // {
        //   routeName: ROUTES.ADMIN.INVOICE_RECEIPT_LIST,
        //   path: "invoice_receipt",
        //   label: '請求書・領収書一覧',
        //   iconSrc: "fas fa-receipt",
        //   childrens: false,
        // },
      ],
      isHoverSidebar: false,
      lastApartment: null
    };
  },
  computed: {
    name() {
      let user = this.$store.getters["auth/getUser"];
      return user?.name;
    },
    listMenus() {
      let listMenus = this.items;
      let currentRouterName = this.$route.name;
      let currentPath = this.$route.path;
      _.forEach(listMenus, (item, key) => {
        if (item.routeName === currentRouterName || currentPath.includes(item.path)) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        // if (item.routeName == ROUTES.ADMIN.FACILITY_LIST && this.lastApartment) {
        //   item.query['filters.apartment_id.equal'] = this.lastApartment.id;
        // }
      });

      _.forEach(listMenus, (item, key) => {
          if (item.childrens !== false) {
            _.forEach(item.childrens, (itemChild, keyChild) => {
              if (itemChild.routeName === currentRouterName || currentPath.includes(itemChild.path)) {
                item.isActive = true;
                itemChild.isActive = true;
              } else {
                itemChild.isActive = false;
              }
              // if (itemChild.routeName === ROUTES.ADMIN.CATEGORY_LIST && this.lastApartment) {
              //   itemChild.query['filters.apartment_id.equal'] = this.lastApartment.id;
              // }
            });
          }
      });

      return listMenus;
    },
  },
  created() {
    // this.getParamsFilters();
  },
  mounted: function () {
    // this.getApartmentList()
    let width = window.innerWidth;
    if (width <= 991) {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-collapse')
    }
  },
  methods: {
    async handleBtnLogoutClick() {
      let role = this.$store.state.auth.currentAuth.role;
      await this.$request.delete(this.ENDPOINT.AUTH_LOGOUT);
      this.$store.commit(`auth/removeAuth`);
      let homeRoute = AUTH[role].routes.dashboard;
      this.$store.commit(`setting/setCurrentRoute`, {
        name : homeRoute
      });
      this.__redirect(AUTH[role].routes.login);
    },
    getParamsFilters() {
      this.$request.get('ui-helper/customer-meals').then(res => {
        this.lastApartment = res.data.apartments[0];
      })
    },
    showTooltip() {
      this.$nextTick(() => {
        if (document.getElementById("name_user_login").scrollWidth > document.getElementById("name_user_login").offsetWidth) {
            $('#name_user_login').tooltip({boundary: 'window'});
        }
      });
    },
    getApartmentList(params) {
      this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, params).then(res => {
          if (Array.isArray(res.data.apartments) && res.data.apartments.length == 1) {
            this.items.map((item) => {
              if (item.routeName === ROUTES.ADMIN.SALE_LIST_DETAIL) {
                item.routeName = ROUTES.ADMIN.SALE_LIST_DETAIL
                item.query = {
                  'filters.apartment_id.equal' : res.data.apartments[0].id,
                }
              }
            })
          }
      })
    },
  }

};

</script>
<style>
#name_user_login {
    white-space: pre;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto !important;
}
.title-main {
  color: #CED2DA;
}
</style>
