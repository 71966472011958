import {ROUTES} from "@constants"
import i18n from "@/lang/i18n";

export default [
  {
    path: 'order-by-code-exporter',
    name: ROUTES.ADMIN.KYY_ORDER_BY_CODE_EXPORT,
    meta: {
      title: 'ExportByCodeOrder',
      breadcrumb: [
        {
          'label': 'breadcrumbs.MasterExportOrder',
        },
      ],
      activeKey: 'admin.order_by_code_exporter'
    },
    component: () => import("@pages/admin/kyy_order_export/OrderByCodeExportPage"),
  },
];
