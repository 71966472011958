<template>
  <div>
    <template>
      <div class="card card-transparent">
        <div class="card-header search-container">
          <div class="search-content">
            <div class="row mt-2 c_filter_area">
              <div class="col-xl-4 col-lg-4 col-sm-4">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                    <button :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getPrevMonth">
                      <i class="fa fa-angle-left c_prev_btn"></i>
                      {{ $t('student_order.prev') }}
                    </button>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                    <month-picker-input
                      ref="month"
                      :class="['c_sale_picker', isDisableButton ? 'c_disable' : '']"
                      lang="vi"
                      :default-month="selectMonth.month"
                      :default-year="selectMonth.year"
                      @change="changeMonth"
                      :show-year="true"
                      input-pre-filled
                    >
                    </month-picker-input>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                    <button :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getNextMonth">
                      {{ $t('student_order.next') }}
                      <i class="fa fa-angle-right c_next_btn"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
              <!-- <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
                <app-select
                  :name="filters.apartment_id_equal.name"
                  input-style="normal"
                  v-model="filters.apartment_id_equal.value"
                  :options-data="apartments"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </template>
    <slot name="table-menu-center"></slot>
    <div class="no-footer bg-white app-table-container">
      <div class="table-responsive">
        <table class="table app-table-list">
          <thead class="thead-light">
          <tr>
            <template v-for="(column, index) in columns">
              <slot :name="`header-cell-${column.name}`">
                <th
                  :key="index"
                  v-if="column.sortable"
                  @click="toggleSortBy(column.name)"
                  :class="[
                      pagination.sortBy === column.name
                        ? pagination.descending
                          ? 'app-sort-desc'
                          : 'app-sort-asc'
                        : 'app-sort',
                      '',
                      column.fitSize ? 'cell-fit-content' : '',
                    ]"
                >
                  {{ column.label }}
                  <i v-if="pagination.sortBy === column.name && pagination.descending" class="fas fa-sort-down"
                     style="vertical-align: inherit;"></i>
                  <i v-if="pagination.sortBy === column.name && !pagination.descending" class="fas fa-sort-up"
                     style="vertical-align: middle;"></i>

                </th>
                <th
                  v-else
                  :key="index"
                  :class="[column.fitSize ? 'cell-fit-content' : '']"
                >
                  {{ column.label }}
                </th>
              </slot>
            </template>
          </tr>
          </thead>
          <tbody id="appTableList">
          <tr v-if="!entries.length">
            <td colspan="100">
                <span v-if="loading">
                  {{ $t("common.loading") }}
                </span>
              <span v-else>
                  {{ $t("common.list empty") }}
                </span>
            </td>
          </tr>
          <tr :key="entry.id" :id="entry.id" :data-id="entry.id" v-for="(entry, entryIndex) in entries">
            <slot
              v-for="(column, columnIndex) in columns"
              :name="`body-cell-${column.name}`"
              :row="entry"
            >
              <td
                :class="['app-align-middle', column.textAlign ? column.textAlign : 'text-left', column.class]"
                :key="columnIndex"
              >
                <p
                  :data-original-title="_.get(entry, column.name)"
                  :class="['app-table-p app-cell-tooltip mb-0', column.class,  checkLineBreak(_.get(entry, column.name))]"
                >{{ _.get(entry, column.name) }}
                </p>
              </td>
            </slot>
          </tr>
          </tbody>
          <slot name="table-footer"></slot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {ROUTES} from "../../../constants";
import {MonthPickerInput} from 'vue-month-picker'

const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SORT_BY = 'EVENT_SORT_BY';
const EVENT_SEARCH = 'EVENT_SEARCH';
const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';

// Default SortableJS
import Sortable from 'sortablejs';
import moment from "moment";

export default {
  name: "AppBasicTable",
  components: {
    MonthPickerInput,
  },
  props: {
    settingColumns: Array,
    tableName: String,
    endpointList: String,
    beforeFetch: String,
    isDisableButton: Boolean,
    paramsBefore: {
      type: Object
    },
    listCanSort: false,
  },

  data() {
    const columns = this.settingColumns.map(val => {
      return {
        sortable: false,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    var cloneFilters = _.cloneDeep(this.filters);
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let condition = splited[2];
        _.forEach(cloneFilters, (filter, index) => {
          if (filter.name === name && filter.condition === condition) {
            cloneFilters[index] = {...cloneFilters[index], value: value}
          }
        })
      }
    })
    let {page, search, sortBy, sortType, perPage} = this.$route.query;
    return {
      columns: columns,
      selectMonth: {
        month: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).month() + 1 : moment().month() + 1,
        year: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).year() : moment().year(),
      },
      entriesRes: {},
      entries: [],
      loading: true,
      pagination: {
        currentPage: page ? page : 1,
        search: search ? search : "",
        sortBy: sortBy ? sortBy : null,
        descending: sortType === "desc",
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
        filters: cloneFilters,
      },
      meta: {},
      apartments: [],
      filters: {
        month: {
          name: "month",
          condition: "equal",
          label: this.$t("reservation.date"),
          value: moment().format('YYYY/MM/01')
        },
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("common.mansion"),
          value: 'all'
        },
      },
      defaultQuery: this.$route.query,
      sortTable: null,
    };
  },

  watch: {
    "$route.query": {
      handler: async function (after, before) {
        if (!_.isEqual(after, before)) {
          await this.setSearchStateByRouteQuery()
          await this.getEntries(false)
        }
      },
      deep: true,
    },
    "filters.apartment_id_equal.value": {
      handler: async function (after, before) {
        if (after != 'all') {
          await this.$router.push({
            name: this.ROUTES.ADMIN.SALE_LIST_DETAIL,
            query: {
              "filters.apartment_id.equal": this.filters.apartment_id_equal.value,
              "filters.month.equal": this.filters.month.value,
            }
          });
        }
      },
    },
  },
  computed: {
    hasFiltersUi() {
      var hasFiltersUi = false;
      _.forEach(this.filters, filter => {
        if (filter.uiType) {
          hasFiltersUi = true;
        }
      })
      return hasFiltersUi;
    },
  },
  mounted() {
    // this.getParamsFilters({apartment_id: 'all'})
    $(".tooltip").tooltip("hide");
  },
  methods: {
    checkLineBreak(note) {
      try {
        if (note) {
          if (note.match(/\n/g)) {
            return 'text-break';
          }
        }
      } catch (err) {
        return ''
      }
    },
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_PER_PAGE_CHANGE])
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    // getParamsFilters(params) {
    //   this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, params).then(res => {
    //     if (res.data.apartments.length == 1) {
    //       this.$router.push({
    //         name: this.ROUTES.ADMIN.SALE_LIST_DETAIL,
    //         query: {
    //           id: res.data.apartments[0].id
    //         }
    //       })
    //     }
    //     this.apartments = res.data.apartments
    //     this.apartments.unshift({
    //       id: "all",
    //       name: ""
    //     })
    //   })
    // },
    async getEntries(firstLoadPage) {
      await this.setSearchStateByRouteQuery(firstLoadPage)
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })

      let endpoint = this.endpointList;
      if (this.beforeFetch) {
        let response = await this.$request.get(this.beforeFetch, this.paramsBefore);
        this.$emit('beforeFetch', response);
      }

      const res = await this.$request.get(endpoint, {...params})
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          entry.amount = entry.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          entry.number_item = entry.number_item.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        if (this.pagination.currentPage > 1 && this.entries.length === 0) {
          this.setCurrentPage(res.data.meta.last_page)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        if (this.pagination.currentPage > 1 && this.entries.length === 0) {
          this.setCurrentPage(1)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          // Count number of paragraph
          let paras = $(el).text().split('\n');
          let count = 0;
          $.each(paras, (index, value) => {
            count++;
          });

          // Show tooltip
          if ((count >= 3) || (el.offsetWidth < el.scrollWidth)) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.dragDropTable()
      this.loading = false;
    },
    async setSearchStateByRouteQuery(firstLoadPage) {
      let {page, search, sortBy, sortType, perPage} = this.$route.query;

      if (firstLoadPage && this.filters.default) {
        if (sortBy == null && this.filters.default.sortBy) {
          sortBy = this.filters.default.sortBy
        }
        if (sortType == null && this.filters.default.sortType) {
          sortType = this.filters.default.sortType
        }
      }

      this.pagination = {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      }
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, key) => {
        newFilters[key] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let condition = splited[2];
          _.forEach(newFilters, (filter, key) => {
            if (filter.name === name && filter.condition === condition) {
              newFilters[key] = {...newFilters[key], value: value}
            }
          })
        }
      })
      await this.$emit('update:filters', newFilters)
    },
    toggleSortBy(columnName) {
      if (this.pagination.sortBy !== columnName) {
        this.pagination.sortBy = columnName
        this.pagination.descending = false;
      } else {
        if (!this.pagination.descending) {
          this.pagination.sortBy = columnName
          this.pagination.descending = true;
        } else {
          this.pagination.sortBy = null
          this.pagination.descending = null;
        }
      }
      this.updateRouteQuery(EVENT_SORT_BY);
    },
    async updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      var {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      _.forEach(events, event => {
        if (event === EVENT_PAGE_CHANGE) {
          if (currentPage > 1) {
            queryObj['page'] = currentPage
          } else {
            delete queryObj['page']
          }
        }
        if (event === EVENT_PER_PAGE_CHANGE) {
          if (parseInt(currentPerPage) !== 10) {
            queryObj['perPage'] = currentPerPage;
          } else {
            delete queryObj['perPage']
          }
        }
        if (event === EVENT_SORT_BY) {
          if (sortBy && sortBy.length) {
            queryObj['sortBy'] = sortBy;
          } else {
            delete queryObj['sortBy'];
          }
          if (sortBy && sortBy.length) {
            queryObj['sortType'] = descending ? 'desc' : 'asc';
          } else {
            delete queryObj['sortType'];
          }
        }
        if (event === EVENT_SEARCH) {
          if (search && search.length) {
            queryObj['search'] = search;
          } else {
            delete queryObj['search'];
          }
          const filters = _.cloneDeep(this.filters);
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.condition] = filter.value.trim()
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.condition];
            }
          })
        }
      })
      this.$emit('getTotal', {
        month: this.selectMonth.year + '/' + this.selectMonth.month + '/01',
        apartment_id: this.filters.apartment_id_equal.value
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        await this.getEntries(false)
      }
    },
    dragDropTable() {
      var self = this;
      let canSort = self.$route.query.hasOwnProperty('filters.apartment_id.equal') && self.$route.query['filters.apartment_id.equal'] != 'all';
      if (self.sortTable) {
        self.sortTable.destroy();
      }
      if (self.listCanSort) {
        self.sortTable = new Sortable.create(appTableList, {
          // Element dragging ended
          sort: canSort,
          onEnd: function (/**Event*/evt) {
            if (canSort) {
              let to = evt.to.querySelectorAll('tr');
              let sortType = self.$route.query.sortType;
              const sortIndex = []
              $.map(to, function (el) {
                sortIndex.push({
                  position: sortType == 'desc' ? to.length - ($(el).index()) : $(el).index() + 1,
                  id: $(el).data('id')
                })
              });
              self.$emit('sortRowTable', sortIndex);
            } else {
              let msgError = '';
              if (self.$route.name == ROUTES.ADMIN.FACILITY_LIST) {
                msgError = '館内施設を並び替えるにはマンションを指定してください。';
              } else if (self.$route.name == ROUTES.ADMIN.CATEGORY_LIST) {
                msgError = 'カテゴリを並び替えるにはマンションを指定してください。'
              }
              self.__noticeError(msgError);
            }
          },
        });
      }

    },
    async getPrevMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let prevMonth = moment(currentMonth).subtract(1, 'months')
      this.selectMonth.month = prevMonth.month() + 1
      this.selectMonth.year = prevMonth.year()
      this.filters.month.value = prevMonth.format('YYYY/MM/01')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let currentMonth = dataSelect.year + '/' + dataSelect.monthIndex + '/01'
      this.filters.month.value = currentMonth
      // this.$refs.month.selectedDate = moment(currentMonth).format('YYYY年MM月')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
    async getNextMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let nextMonth = moment(currentMonth).add(1, 'months')
      this.selectMonth.month = nextMonth.month() + 1
      this.selectMonth.year = nextMonth.year()
      this.filters.month.value = nextMonth.format('YYYY/MM/01')
      // this.$refs.month.selectedDate = nextMonth.format('YYYY年MM月')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
  }
};
</script>

<style scoped lang="scss">
.c_prev_month {
  width: 100px;
}
.c_font_size_40px {
  font-size: 40px;
}
.c_chart_area {
  height: 100%;
  border: 1px solid #d1cdcd;
  #chart {
    //padding-left: 8%;
  }
}

.c_background_gray {
  background-color: #FAFAFA;
  height: 46%;
}

.c_text_align_center {
  text-align: center;
  font-weight: bold;
}

.c_border_radius_1 {
  border-radius: 10px;
}

.c_w_30_percent {
  width: 30%;
}

.c_w_10_percent {
  width: 10%;
}

.app-table-container p.app-table-p.text-break {
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre;
}
.c_disable {
  pointer-events: none;
  opacity: 1;
}
.c_z_index_11 {
  z-index: 11;
}
button[disabled] {
  cursor: pointer;
}
button {
  min-height: 45px;
}
//.c_disable {
//
//}
</style>
